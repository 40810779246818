import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.css']
})
export class PricesComponent {

  englishPriceList = [
    { service: 'Blow Dry / Silk Press', price: '$40 - $60'},
    { service: 'Add Trim', price: '$15'},
    { service: 'Hair Cut', price: '$30 - $40', additional: '(Does not include blow dry / styling)'},
    { service: 'Color (Root Touch-Up)', price: 'Starting at $50', additional: '(Does not include blow dry / styling)'},
    { service: 'Color (Full Hair)', price: 'Starting at $100', additional: '(Does not include blow dry / styling)'},
    { service: 'Eye Brow Wax', price: '$10'},
    { service: 'Eye Brow Tint', price: '$40'},
    { service: 'Eye Lash Cluster', price: '$50'},
    { service: 'Deep Conditioning Mask', price: '$10'},
    { service: 'Hair Steaming', price: '$25'},
  ];

  englishConsultationRequiredList = [
      'Botox / Keratin Treatment',
      'Highlights',
      'Hair Extensions',
      'Curly Perm'
  ];

  spanishPriceList = [
    { service: 'Secado de Pelo', price: '$40 - $60'},
    { service: 'Agregar Recorte de Punta', price: '$15'},
    { service: 'Corte de Cabello', price: '$30 - $40', additional: '(No incluye secador)'},
    { service: 'Retoque (Raíz de Color)', price: 'Comenzando en $50', additional: '(No incluye secador)'},
    { service: 'Color (Cabello Completo)', price: 'Comenzando en $100', additional: '(No incluye secador)'},
    { service: 'Cera para Cejas', price: '$10'},
    { service: 'Tinte de Cejas', price: '$40'},
    { service: 'Pestañas', price: '$50'},
    { service: 'Mascarilla Profunda', price: '$10'},
    { service: 'Vaporización de Cabello', price: '$25'},
  ];

  spanishConsultationRequiredList = [
    'Tratamiento de Botox / Queratina',
    'Highlights / Rayitos / Mechas',
    'Extensiones de Cabello',
    'Permante Rizos'
  ];

}
